<template>
    <div class="content">
      <Header />
      <div class="info">
        <div class="plaha">
          <div class="searchForm flex ui fjcS">
            <div class="col10">
              <span>Артикул</span>
              <input type="text" v-model="searchArticle" />
            </div>

            <div class="col10 forBtn">
              <a href="#!" class="searchBtn" @click.prevent="searchOrders"
                >Найти</a
              >
            </div>
          </div>
        </div>
        <div class="productsList">
            <div class="productsList_header">
                <div class="col w15">
                    Артикул
                </div>
                <div class="col w60">
                    Наименование
                </div>
                <div class="col w15">
                    Цена
                </div>
                <div class="col w10">
                    
                </div>
            </div>
            <div class="productsList_body">

                <div class="row" 
                    v-for="(item, key) in productsList.data"
                    :key="key">
                    <div class="col w15">
                        {{ item.article }}
                    </div>
                    <div class="col w60">
                        {{ item.name }}
                    </div>
                    <div class="col w15">
                        {{ item.price!=null?item.price:0 }} руб.
                    </div>
                    <div class="col w10">
                        <a href="#!" :data-id="item.id" class="ico-edit" @click.prevent="this.edit(item)"></a>
                    </div>
                </div>
              
            </div>
        </div>

  
        <div class="plaha">
        <div class="pagination flex ui">
          <Pagination :totalpage="productsList.totalPage" link="products" />
        </div>
      </div>
       
      </div>
    </div>
    <div class="editableWindow" v-if="isShowWindow">
        <a href="#!" class="close" @click.prevent="isShowWindow=false"></a>
        <h3>Редактировать товар</h3>
        <div class="row">
            <input type="text" v-model="editName">
        </div>
        <div class="row">
            <input type="text" v-model="editPrice">
        </div>
        <div class="row">
            <a href="#!" :data-id="editId" @click.prevent="this.save()">Сохранить</a>
        </div>
    </div>
  </template>
  
<style lang="scss" scoped>
    .editableWindow {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 21px 30px;
        width: 40%;
        background-color: #eeeeee;
        border-radius: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        .close {
            background: url(../assets/ico-del.svg) no-repeat center center;
            background-size: 15px;
            position: absolute;
            width: 15px;
            height: 15px;
            right: 30px;
            top: 17px;
        }
        .row {
            padding: 10px 0 10px 0;
            input {
                font-size: 14px;
                padding: 8px 12px;
                border: 1px solid gray;
                outline: none;
                width: 100%;
            }
            a {
                padding: 7px 19px;
                color: white;
                background: #000ea8;
                display: inline-block;
                border-radius: 5px;
                transition: 0.3s ease-out;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
    .productsList {
        margin-top: 25px;
        .productsList_header {
            background-color: rgb(219, 219, 219);
            display: flex;
            justify-content: flex-start;
            border-radius: 5px;
            .col {
                font-size: 16px;
                padding: 9px 10px;
                font-weight: 600;
                color: #3d3d3d;
            }
        }
        .productsList_body {
            margin: 20px 0;
            .row {
                display: flex;
                justify-content: flex-start;
                border-bottom: 1px solid #eeeeee;
                .col {
                    font-size: 14px;
                    padding: 8px 10px;
                    font-weight: 500;
                    color: #3d3d3d;
                }
            }
        }
        .w50 {
            width: 50%;
        }
        .w60 {
            width: 60%;
        }
        .w15 {
            width: 15%;
        }
        .w20 {
            width: 20%;
        }
        .w10 {
            width: 10%;
        }
    }
</style>


  <script>
import api from "@/components/app/api";
import Pagination from "@/components/app/pagination";
import Header from "@/components/app/header";
import Helper from "@/components/app/helper";
export default {
  name: "Home",
  data() {
    return {
      productsList: [], //Список заказов
      searchArticle: "",
      editId: 0,
      editName: "",
      editPrice: 0,
      isShowWindow: false  
    };
  },
  watch: {
    "$route.params.id": {
      handler: function () {
        this.list();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    searchOrders() {
        console.log(this.searchArticle);
        this.$route.params.id = 1
        this.list()
    },
    async save() {
        console.log(this.editId, this.editName, this.editPrice);

        let response = await api.methods.PRODUCT_save({
            id: this.editId,
            name: this.editName,
            price: this.editPrice
      });
        if(response.data=="Товар сохранен!") {
            this.isShowWindow = false
            this.editName = ""
            this.editPrice = ""
            this.editId = 0
            this.list()
            Helper.methods.warning(response.data, "ok");
        }
    },
    edit(item) {
        this.isShowWindow = true
        this.editName = item.name
        this.editPrice = item.clearPrice
        this.editId = item.id
    },
    async list() {
        this.productsList = await api.methods.PRODUCT_list({
        page: this.$route.params.id == undefined? 1: this.searchArticle==''?this.$route.params.id:1,
        searchArticle: this.searchArticle,

      });
    },

  },
  async mounted() {
    this.list()
  },
  async created() {},
  components: {
   // DropDown,
    Header,
    Pagination,
  },
};
</script>
