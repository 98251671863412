<template>
    <div class="list flex">
            <a @click.prevent="this.$router.push(`/${link}/` + 1)" class="firstPage"></a>
            <a @click.prevent="this.$router.push(`/${link}/` + (Number(this.$route.params.id)-1))" v-if="Number(this.$route.params.id)-1">{{Object.keys(this.$route.params).length > 0?Number(this.$route.params.id)-1:2}}</a>
            <a @click.prevent="this.$router.push(`/${link}/`+Object.keys(this.$route.params).length > 0?this.$route.params.id:1)"  class="active">{{Object.keys(this.$route.params).length > 0?this.$route.params.id:1}}</a>
            <a v-if="(Number(totalpage)>=Number(this.$route.params.id)+1) || (Number(totalpage)>1 && this.$route.params.id==undefined)" :data-id="totalpage" @click.prevent="this.$router.push(Object.keys(this.$route.params).length > 0?`/${link}/`+(Number(this.$route.params.id)+1):`/${link}/`+2)" >{{Object.keys(this.$route.params).length > 0?Number(this.$route.params.id)+1:2}}</a>
            <a v-if="(Number(totalpage)>=Number(this.$route.params.id)+2) || (Number(totalpage)>2 && this.$route.params.id==undefined)" :data-id="totalpage" @click.prevent="this.$router.push(Object.keys(this.$route.params).length > 0?`/${link}/`+(Number(this.$route.params.id)+2):`/${link}/`+3)" >{{Object.keys(this.$route.params).length > 0?Number(this.$route.params.id)+2:3}}</a>
            <a v-if="(Number(totalpage)>=Number(this.$route.params.id)+3) || (Number(totalpage)>3 && this.$route.params.id==undefined)" :data-id="totalpage" @click.prevent="this.$router.push(Object.keys(this.$route.params).length > 0?`/${link}/`+(Number(this.$route.params.id)+3):`/${link}/`+4)" >{{Object.keys(this.$route.params).length > 0?Number(this.$route.params.id)+3:4}}</a>
            <a @click.prevent="this.$router.push(`/${link}/` + totalpage)" class="lastPage"></a>
          </div>
</template>
<style lang="scss" scoped>
.list {
  a {
    cursor: pointer;
  }
}
</style>
<script>

export default {
  props: ['totalpage', 'link'],
  data() {
    return {

    }
  },
  mounted() {
    console.log(this.$props);
    console.log("total", this.$props.totalpage);
    console.log("link", this.$props.link);
  }
}
</script>
